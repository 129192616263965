<template>
  <!-- 
      TODO Rename translation key to OnfidoVerifyIdentity.[value] 
      Current names suggest page usage
  -->
  <AppCardImageTitle
    :src="require('@galileo/assets/images/illustrations/man-detective.svg')"
    alt="Identity"
    :title="$t('PageSendMoneyVerifyIdentity.Title').value"
  >
    <ol class="ol">
      <li>{{ $t('PageSendMoneyVerifyIdentity.Step1').value }}</li>
      <li>
        {{ $t('PageSendMoneyVerifyIdentity.Step2').value }}
        <strong>{{ $t('PageSendMoneyVerifyIdentity.Step2Readable').value }}</strong>
        {{ $t('PageSendMoneyVerifyIdentity.Step2And').value }}
        <strong>{{ $t('PageSendMoneyVerifyIdentity.Step2NoGlare').value }}</strong>
      </li>
    </ol>
  </AppCardImageTitle>
</template>

<script>
import { useI18nStore } from '@galileo/stores'

import { AppCardImageTitle } from '@oen.web.vue2/ui'

export default {
  name: 'VerifyIdentityOnfido',
  components: {
    AppCardImageTitle,
  },
  setup() {
    const { $t } = useI18nStore()
    return {
      $t,
    }
  },
}
</script>

<style scoped>
.ol {
  @apply text-left;
}
</style>
