<template>
  <div>
    <AppModal v-model="showModal" :title="$t('PageAccountVerifyIdentityOnfido.TitleModal').value">
      <template #header>
        <AppModalHeader>
          <template #right>
            <AppBackButton
              analytics-name="account-verify-identity-onfido-close"
              @click="showLeaveConfirmationDialog = true"
            />
          </template>
        </AppModalHeader>
      </template>

      <VerifyIdentityOnfido></VerifyIdentityOnfido>

      <template #footer>
        <AppModalFooter>
          <AppButton analytics-name="account-verify-identity-onfido-continue" @click="verify">
            {{ $t('PageAccountVerifyIdentityOnfido.ButtonContinue').value }}
          </AppButton>
        </AppModalFooter>
      </template>
    </AppModal>
    <AppDialogConfirmation
      v-model="showLeaveConfirmationDialog"
      analytics-name="account-verify-identity-onfido-leave-confirmation"
      :title="$t('PageSendMoneyVerifyIdentityCancel.Title').value"
      :cancel-btn-text="$t('PageSendMoneyVerifyIdentityCancel.ButtonYes').value"
      :confirm-btn-text="$t('PageSendMoneyVerifyIdentityCancel.ButtonNo').value"
      @cancel="$router.go(-1)"
      @confirm="showLeaveConfirmationDialog = false"
    >
      <p>{{ $t('PageSendMoneyVerifyIdentityCancel.Message').value }}</p>
    </AppDialogConfirmation>
  </div>
</template>

<script>
import { useRouter } from '@galileo/composables/useRouter'
import { ref } from '@vue/composition-api'
import VerifyIdentityOnfido from '@galileo/components/Views/Identification/VerifyIdentityOnfido'
import { useI18nStore } from '@galileo/stores/i18n'
import {
  SEGMENT_EVENTS,
  SEGMENT_IDENTITY_VERIFICATION_PLATFORMS,
} from '@galileo/constants/segmentAnalytics'

import {
  AppButton,
  AppBackButton,
  AppModal,
  AppModalHeader,
  AppModalFooter,
  AppDialogConfirmation,
} from '@oen.web.vue2/ui'

export default {
  name: 'AccountVerifyIdentity',
  components: {
    AppButton,
    AppBackButton,
    AppModal,
    AppModalHeader,
    AppModalFooter,
    VerifyIdentityOnfido,
    AppDialogConfirmation,
  },

  setup() {
    const { $t } = useI18nStore()
    const router = useRouter()

    const showModal = ref(true)
    const showLeaveConfirmationDialog = ref(false)
    const verify = () => {
      // Work out here if i should go to onfido or veriff
      router.replace({ name: 'AccountOnfido' })
    }

    return {
      showModal,
      verify,
      $t,
      showLeaveConfirmationDialog,
    }
  },
}
</script>

<style scoped></style>
